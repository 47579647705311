import { useState } from 'react';

import { getUnAuth, get, post } from '../config/axiosConfig';
import { setLyocCookie } from '../Cookies';


const Login = ({setToken, advanceTimeline}) => {
    const numPlayers = 4;
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [lobbyId, setLobbyId] = useState();

    async function loginUser(username, password) {
        const loginParams = `login?username=${username}&password=${password}`
      
        try {
          let response = await getUnAuth(loginParams);
          return response.data;
        } catch (e) {
          console.log(e);
        }
      }

    const handleSubmit = async e => {
        e.preventDefault();
        const token = await loginUser(username, password);
        if(token == null){
          alert('Unable to login. Are the email and password correct?');
          return;
        }

        // Redundant, but so we can load the token from the cookie on reload
        setLyocCookie(token);
        setToken(token);
      }

    const openLinkInNewTab = (e, url) => {
      e.preventDefault();
      const newTab = window.open(url, '_blank', 'noopener,noreferrer');
      if ( newTab ) newTab.opener = null;
    }

    return (
      <div className="App">
        <div className='mainBody'>
            <h2>Last Year of Carbon</h2>

            <button onClick={(e) => openLinkInNewTab(e, 'https://lyoc.shop')}>Buy A Copy</button>
            
            <button>Watch The Board Game Tutorial</button>

            <button>Read the Rulebook</button>

            <form onSubmit={handleSubmit} className='login-form'>
              <h3>Login to play</h3>
                <label>
                  <p>Email</p>
                  <input type="text" onChange={e => setUsername(e.target.value)}/>
                </label>
                <label>
                  <p>Password</p>
                  <input type="password" onChange={e => setPassword(e.target.value)}/>
                </label>
                <div>
                  <button type="submit">Submit</button>
                </div>
              </form>
        </div>
      </div>
    );
}

export default Login;