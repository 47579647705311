import { lazy, useState, useEffect } from 'react';

import { get } from './config/axiosConfig';
import getLyocCookie from './Cookies';

import Login from './components/Login';

import './App.css';

const Game = lazy(() => import('./components/Game'));

function App() {
  const cookieName = 'lyoc_cookie';
  const [token, setToken] = useState(getLyocCookie(cookieName || ''));

  const [defaultName, setDefaultName] = useState('');
  const [defaultColor, setDefaultColor] = useState('');


  useEffect(() => {
    if(getLyocCookie()){
      setToken(getLyocCookie(cookieName));
    }
}, []);

  useEffect(()=>{
    if(token && token !== ''){
      async function getUserMeta(){
        let response = await get('/username'); // GET PLAYER NAME && DEFAULT COLOR
        console.log(response.data.username + ' ' + response.data.default_color)
        setDefaultName(response.data.username);
        setDefaultColor(response.data.default_color);
      }

      getUserMeta();
    }
  }, [token]);

  if(!token){
    return(
      <Login setToken={setToken}/>
    );
  }

  return (<Game defaultName={defaultName} defaultColor={defaultColor}/>)
}

export default App;
