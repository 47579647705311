import axios from 'axios';
import { API_URL } from './envVars'
import getLyocCookie from './../Cookies';

export const axiosClient = axios.create({
    baseURL: API_URL// API_URL
});

/** i just did this so i didn't have to write the header every time */
function getHeader(){
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${getLyocCookie()}`
        }
    }

    return config;
}

export async function getUnAuth (url) {
    try {
        let response = await axiosClient.get(url);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export async function get (url) {
    const config = getHeader();
    try {
        let response = await axiosClient.get(url, config);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export async function getBlob (url) {
    const config = getHeader();
    try {
        let response = await axiosClient.get(url, config);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export async function post (url, body) {
    const config = getHeader();
    try {
        let response = await axiosClient.post(url, body, config);
        return response;
    } catch (error) {
        console.log(error);
    }
}
